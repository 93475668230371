import React, { useState } from 'react';
import { useDidMountEffect } from '@hooks/useDidMountEffect';
import ImgArrow from '@images/icon/arrow.svg'
import ImgArrowTwo from '@images/icon/arrowtwo.svg'
import './style.scss';

const Pagination = ({ initialCurrentPage, numPages, numPageBlock, onClick }) => {
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);

  const isLastBlock = () => {
    const totalBlockCount = Math.ceil(numPages / numPageBlock);
    return totalBlockCount === Math.floor((currentPage - 1) / numPageBlock) + 1;
  };

  const onClickPrev = () => setCurrentPage((page) => page - 1);
  const onClickNext = () => setCurrentPage((page) => page + 1);
  const onClickPagingButton = (num) => setCurrentPage(num);
  const onClickVeryFront = () => setCurrentPage(1);
  const onClickVeryBack = () => setCurrentPage(numPages);

  useDidMountEffect(() => {
    onClick(currentPage);
  }, [currentPage, onClick]);

  return (
    <div className="pagination">
      <button className="pagi-btn" onClick={onClickVeryFront} disabled={Math.floor((currentPage - 1) / numPageBlock) === 0}>
        
        <img src={ImgArrowTwo} alt="Very Front" />
      </button>
      <button className="pagi-btn" onClick={onClickPrev} disabled={currentPage === 1}>
        
        <img src={ImgArrow} alt="Prev" />
      </button>
      {Array.from({ length: isLastBlock() ? numPages % numPageBlock || numPageBlock : numPageBlock }).map((_, i) => {
        const numPage = i + 1 + Math.floor((currentPage - 1) / numPageBlock) * numPageBlock;
        return (
          <button
            key={numPage}
            // style={currentPage === numPage ? { backgroundColor: 'tomato' } : null}
            className={currentPage === numPage ? 'countbtn active' : 'countbtn'}
            onClick={() => onClickPagingButton(numPage)}
          >
            {numPage}
          </button>
        );
      })}
      <button className="pagi-btn next-btn" onClick={onClickNext} disabled={currentPage === numPages}>
        
        <img src={ImgArrow} alt="Next" />
      </button>
      <button className="pagi-btn next-btn" onClick={onClickVeryBack} disabled={isLastBlock()}>
        
        <img src={ImgArrowTwo} alt="Very Back" />
      </button>
    </div>
  );
};

export default Pagination;
