import { Link } from 'gatsby';
import React from 'react';
import './style.scss';

const PostList = ({ posts }) => {
  return (
    <ul className="post-list">
      {posts.map((node) => {
        const {
          id,
          fields: { slug },
          frontmatter: { author, date, summary, title },
        } = node;

        return (
          <li key={id}>
            <article>
              <Link to={`/blog${slug}`}>
                <h3>{title}</h3>
              </Link>
              <p>{summary}</p>
              <div>
                <time dateTime={date}>{date}</time>
                <span>{author}</span>
              </div>
            </article>
          </li>
        );
      })}
    </ul>
  );
};

export default PostList;
