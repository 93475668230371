import { graphql, navigate } from 'gatsby';
import React, { useCallback } from 'react';
import CategoryList from '@components/Blog/CategoryList';
import PostList from '@components/Blog/PostList';
import Pagination from '@components/common/Pagination';
import Seo from '@components/common/Seo';
import Layout from '@layouts/Layout';
import './style.scss';

const BlogListTemplate = ({ data, pageContext }) => {
  const {
    allMarkdownRemark: { nodes: posts },
  } = data;
  const { category, currentPage, numPages } = pageContext;

  const onClickPaginationButton = useCallback(
    (pageNum) =>
      navigate(pageNum === 1 ? (category === 'all' ? `/blog` : `/blog/${category}`) : `/blog/${category}/${pageNum}`),
    [category]
  );

  return (
    <Layout>
      <section id="blog-list">
        <div className="container">
          <div className="row">
            <div className="blog-list">
              {/* category list */}
              <div className="category-list-wrapper">
                <CategoryList />
              </div>

              {/* post list */}
              <div className="post-list-wrapper">
                <PostList posts={posts} />
              </div>

              {/* pagination */}
              <div className="pagination-wrapper">
                <Pagination
                  initialCurrentPage={currentPage}
                  numPages={numPages}
                  numPageBlock={5}
                  onClick={onClickPaginationButton}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogListTemplate;

export const Head = () => {
  return <Seo title="기술블로그 | 디지엠유닛원" />;
};

export const query = graphql`
  query ($category: String, $limit: Int, $skip: Int) {
    allMarkdownRemark(
      filter: {fields: {categories: {eq: $category}}}
      sort: {
        fields: [frontmatter___date, frontmatter___title],
        order: [DESC, DESC]
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        fields {
          slug
          categories
        }
        frontmatter {
          author
          date(formatString: "YYYY.MM.DD")
          summary
          title
        }
        id
      }
    }
  }
`;
